import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

import { SEO } from "../helpers"
import {
  Hero,
  InterviewList,
  ArticleList,
  EditorsChoiceList,
  Header,
} from "../compositions"
import { Frame, TitledSection } from "../layout"

import {
  IndexQuery,
  GraphCms_Page,
  GraphCms_Interview,
  GraphCms_Article,
  GraphCms_EditorsChoice,
} from "../../../graphql-types"

type Props = {
  data: IndexQuery
}

function IndexPage({ data }: Props) {
  const {
    graphCmsPage,
    allGraphCmsInterview,
    allGraphCmsArticle,
    allGraphCmsEditorsChoice,
    file,
  } = data

  const image = getImage(file)
  const background = convertToBgImage(image)

  return (
    <Frame>
      <SEO page={graphCmsPage as GraphCms_Page} />
      <BackgroundImage
        Tag="section"
        {...background}
        style={{
          backgroundRepeat: "repeat",
          backgroundSize: "540px 540px",
          backgroundPosition: "top",
        }}
        preserveStackingContext
      >
        <Header className="z-10" />
        <Hero />
        <div className="h-2 bg-gradient"></div>
      </BackgroundImage>

      <TitledSection
        title="Yurt Dışında Yaşam Röportajları"
        tagline="Yurt dışına gidenler neler yaparak orada iş buldular, ne gibi sorunlarla karşılaşıp o sorunları nasıl çözdüler. Sizler için derlediğimiz röportaj serileri."
        label="Yurt Dışında Yaşam Hakkındaki Röportajlar"
        to="/roportajlar"
      >
        <InterviewList
          interviews={allGraphCmsInterview.nodes as GraphCms_Interview[]}
        />
      </TitledSection>

      <TitledSection
        title="Yurt Dışında Yaşamak için Bilmeniz Gerekenler"
        tagline="Yurt dışında yaşamaya karar vermeden önce bilmemiz gereken bazı önemli bilgileri sizler için topladık."
        label="Yurt Dışında Yaşam Hakkında Bilgiler &amp; Öneriler"
        to="/yazilar"
      >
        <ArticleList
          articles={allGraphCmsArticle.nodes as GraphCms_Article[]}
        />
      </TitledSection>
    </Frame>
  )
}

export default IndexPage

export const query = graphql`
  query Index {
    file(relativePath: { eq: "bg.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 987
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    graphCmsPage(url: { pathname: { eq: "/" } }) {
      id
      title
      description
      url {
        ...UrlFields
      }
      faq {
        ...DatasetFields
      }
      image {
        ...AssetFields
      }
      breadcrumb {
        ...UrlFields
      }
    }
    allGraphCmsInterview(sort: { fields: createdAt, order: DESC }, limit: 12) {
      nodes {
        id
        publishedAt
        createdAt
        title
        excerpt
        contributor {
          name
          occupation
          country {
            name
            flag {
              ...AssetFields
            }
          }
          avatar {
            ...AssetFields
          }
        }
        page {
          url {
            ...UrlFields
          }
          image {
            ...AssetFields
          }
        }
      }
    }
    allGraphCmsEditorsChoice(
      sort: { fields: createdAt, order: DESC }
      limit: 2
    ) {
      nodes {
        id
        image {
          ...AssetFields
        }
        article {
          id
          publishedAt
          createdAt
          title
          excerpt
          page {
            ...PageFields
          }
        }
      }
    }
    allGraphCmsArticle(sort: { fields: createdAt, order: DESC }, limit: 2) {
      nodes {
        id
        publishedAt
        createdAt
        title
        excerpt
        page {
          url {
            ...UrlFields
          }
          image {
            ...AssetFields
          }
        }
      }
    }
  }
`
